import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import PageWithHeader from '../components/pageWithHeader';
import { richTextOptions } from '../helpers/contentfulHelpers';
import style from './product.module.scss';
import { Link } from 'gatsby';

export default function Product({pageContext, path}) {
    const { data, assets, defaultImageSrc } = pageContext;

    const image = data.headerImage
        ? data.headerImage.localFile.childImageSharp.fluid.src
        : defaultImageSrc;

    return (
        <PageWithHeader title={data.productName} url={path} headerImage={image} >
            <div className={style.productWrapper}>
                <article>
                    <div>{documentToReactComponents(data.content.json, richTextOptions(assets))}</div>
                </article>
                <hr/>
                <div className={style.articleFooter}>
                    <h3>Powerful Solution. Effective Support.</h3>
                    <Link to="/contact/"><button>Contact us</button></Link>
                </div>
            </div>
        </PageWithHeader>
    )
}
